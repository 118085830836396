import * as React from "react"
import { useEffect } from "react"
import { Helmet } from "react-helmet"

// markup
const HeadComponent = (props) => {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  })
  var title = 'Kvotum - Fintech Solutions'
  if(props.title){
    title = 'Kvotum - '+props.title
  }

  return (
    <Helmet>
        <title>{title}</title>
        <meta name="description" content="Kvotum provides innovational fintech products, both for end-users and businesses. We offer a unique secondary market platfrorm for p2p lending providers and we offer a crowdfunding and P2P lending software and service package."/>

        <script src="https://cmp.osano.com/16CVvPSKZriAu3eHB/9f62b74f-44ca-4e65-b30e-4d59f44e6625/osano.js"></script>
    </Helmet>
  )
}

export default HeadComponent